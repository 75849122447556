<template>
  <div>
    <b-form>
      <b-row>
        <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar :src="null" :text="avatarText(dados.nomeCompleto)" size="104px" rounded />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ dados.nomeCompleto }}
                </h4>
                <span class="card-text">CPF: {{ dados.cpf }}</span
                ><br />
                <span class="card-text"
                  >Antecipação: <span class="badge badge-light-primary badge-pill">{{ dados.codigoAntecipacao }}</span></span
                >
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100" aria-describedby="gridRelatorio">
            <tr>
              <th class="pb-50">
                <feather-icon icon="BriefcaseIcon" class="mr-75" />
                <span class="font-weight-bold">Órgão</span>
              </th>
              <td class="pb-50">
                {{ dados.nomeOrgao }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Categoria</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ dados.categoria }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Telefone</span>
              </th>
              <td>
                {{ dados.telefone }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="AtSignIcon" class="mr-75" />
                <span class="font-weight-bold">E-mail</span>
              </th>
              <td>
                {{ dados.email }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col md="3">
          <b-form-group label="Valor da Antecipação" label-for="valor">
            <b-form-input
              id="valor"
              placeholder="Valor da Antecipação"
              :value="formatarValor(dados.valorAntecipacao)"
              v-number="number"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Parcelas" label-for="parcelas">
            <b-form-input id="parcelas" placeholder="Parcelas" v-model="dados.quantidadeParcelas" :disabled="true" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Data Antecipação" label-for="dataAntecipacao">
            <b-form-input
              id="dataAntecipacao"
              class="form-control"
              type="text"
              placeholder="DD/MM/AAAA"
              :value="formatarData(dados.dataAntecipacao)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Juros Pró-Rata" label-for="jurosProRata">
            <b-form-input
              id="jurosProRata"
              placeholder="Juros Pró-Rata"
              :value="formatarValor(jurosProRata)"
              v-number="number"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="dados.statusPagamento !== 'Pago'">
        <b-col class="mb-1">
          <b-button variant="primary" id="gerarAntecipacao" @click="abrirModalGerarAntecipacao">Gerar Antecipação</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-table
            striped
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="isBusy"
          >
            <template #cell(valorAntecipado)="data">
              <!-- Verifica se é a última linha -->
              <span v-if="isFirstRow(data) == true"
                >{{ formatarValor(data.item.valorAntecipado) }}
                <b-badge pill>Pro rata: {{ formatarValor(jurosProRata) }}</b-badge>
              </span>
              <span v-else>{{ formatarValor(data.item.valorAntecipado) }} </span>
            </template>

            <template #cell(valor)="row">
              {{ formatarValor(row.item.valor) }}
            </template>
            <template #cell(vencimento)="row">
              {{ formatarData(row.item.vencimento) }}
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-card-body>
        </b-col>
      </b-row>
    </b-form>
    <b-modal
      id="modal-gerar-antecipacao"
      ref="modal-gerar-antecipacao"
      centered
      title="Gerar Antecipação"
      no-close-on-backdrop
      ok-only
    >
      <p class="mb-0">Selecione o tipo para a geração da Antecipação:</p>
      <b-form-select v-model="selected" :options="options"></b-form-select>
      <p class="mb-0 mt-1">Selecione a data de vencimento:</p>
      <b-form-group label="" label-for="dataVencimento">
        <validation-observer ref="boletoRules" tag="form">
          <validation-provider #default="{ errors }" name="Data de Vecimento Arrecação" rules="required">
            <b-form-datepicker
              id="dataVencimento"
              v-model="selectedDate"
              placeholder="DD/MM/AAAA"
              :hide-header="true"
              :initial-date="new Date()"
              label-help=""
              :min="new Date()"
              label-calendar="Calendário"
              label-close-button="Fechar"
              label-current-month="Mês atual"
              label-next-decade="Próxima década"
              label-next-month="Próximo mês"
              label-next-year="Próximo ano"
              label-prev-decade="Década anterior"
              label-prev-month="Mês anterior"
              label-prev-year="Ano anterior"
              label-select-month="Selecione o mês"
              label-select-year="Selecione o ano"
              label-selected="Selecionado"
              label-today="Hoje"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>
      </b-form-group>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="gerarAntecipacao">Gerar</b-button>
      </template>
    </b-modal>
    <guiaAntecipacao
      ref="guiaAntecipacao"
      :dados="dados"
      :parametro="parametroDadosAntecipacao"
      :fecharModal="fecharModalGuiaAntecipacao"
    />
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import { isNumber, formatarValor, formatarData, formatarCodigoSequencial, formatarParaNumber } from '@/libs/utils'
  import { geraBoleto, consultaBoletoGerado, adicionaBoletoNaGeracaoDeRemessa } from '@/utils/boleto/index'
  import { avatarText } from '@core/utils/filter'
  import useJwt from '@/auth/jwt/useJwt'
  import guiaAntecipacao from './components/guiaAntecipacao.vue'
  import axios from 'axios'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      formatarData,
      formatarCodigoSequencial,
      formatarValor,
      formatarParaNumber,
      guiaAntecipacao,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
      parametroDadosAntecipacao: {},
    },
    setup() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        avatarText,
      }
    },
    data() {
      return {
        isNumber,
        formatarValor,
        formatarData,
        formatarCodigoSequencial,
        dados: {},
        isBusy: false,
        jurosProRata: 0,
        number: {},
        sortBy: 'parcelaAtual',
        sortDesc: false,
        sortDirection: 'asc',
        perPage: 6,
        currentPage: 1,
        fields: [
          { key: 'parcelaAtual', label: 'Nº Parcela' },
          {
            key: 'valorAntecipado',
            label: 'Valor da Parcela',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          { key: 'vencimento', label: 'Vencimento', formatter: (value) => (value ? formatarData(value) : '') },
        ],

        totalRows: 0,
        items: [],
        taxaShow: {},
        selected: '',
        selectedDate: '',
        options: [
          { value: 'guia', text: 'Guia' },
          { value: 'boleto', text: 'Boleto' },
        ],
      }
    },
    async mounted() {
      await this.carregarParcelas()
    },
    methods: {
      isFirstRow(data) {
        const parcelaItems = this.items[0].parcela
        let parcelaTabela = data.item.parcelaAtual

        parcelaTabela = Number(data.item.parcelaAtual.split('/')[0])

        if (parcelaTabela == parcelaItems) {
          return true
        }
      },
      async carregarParcelas() {
        this.isBusy = true
        let rotaApi = `renegociacao/GetCarregarDadosParcelasAntecipacao`

        await useJwt
          .post(rotaApi, { antecipacaoId: this.parametro.id })
          .then(async (response) => {
            this.dados = response.data
            this.items = this.dados.dadosParcelas
            const dadosFiltrados = this.items.sort((a, b) => a.parcela - b.parcela)
            this.jurosProRata = dadosFiltrados[0].valorAntecipado - dadosFiltrados[0].amortizacao
            this.totalRows = this.dados.dadosParcelas.length
            this.isBusy = false
          })
          .catch((error) => {
            console.error(error)
          })
      },
      abrirModalGerarAntecipacao() {
        this.$refs['modal-gerar-antecipacao'].show()
      },
      async gerarAntecipacao() {
        const tipoBaixa = this.selected
        const dadosBaixa = {
          antecipacaoId: this.parametro.id,
          tipoBaixaAntecipacao: tipoBaixa,
        }
        useJwt.put('renegociacao/TipoBaixaAntecipacao', dadosBaixa)

        if (tipoBaixa == 'boleto') {
          await this.gerarBoleto(this.parametroDadosAntecipacao)
        } else if (tipoBaixa == 'guia') {
          await this.geraGuia()
        }

        this.$refs['modal-gerar-antecipacao'].hide()
        this.$emit('carregar-grid')
      },
      async gerarBoleto(item) {
        this.$refs.boletoRules.validate().then(async (success) => {
          if (success) {
            this.$message.info('Iniciando a geração do boleto.')

            const institutoId = item.institutoId
            const tomadorId = item.tomadorId
            const renegociacaoId = item.id
            const valorBoleto = item.valorAntecipacao

            let rt = await adicionaBoletoNaGeracaoDeRemessa({
              institutoId: institutoId,
              renegociacaoAntecipacaoId: renegociacaoId,
              tomadorId: tomadorId,
              geradoPor: 'porAntecipacao',
              dataVencimento: this.selectedDate,
            })

            if (rt.status != 200) {
              rt = await consultaBoletoGerado({
                institutoId: institutoId,
                renegociacaoAntecipacaoId: renegociacaoId,
                tomadorId: tomadorId,
                geradoPor: 'porAntecipacao',
                dataVencimento: this.selectedDate,
              })
            }

            const obj = await geraBoleto(institutoId, tomadorId, {
              valor: valorBoleto,
              dataVencimento: this.selectedDate,
              nossoNumero: `${rt.data.id}`,
              numeroDocumento: `${rt.data.id}`,
            })

            const resp = await useJwt.post('boleto/geraBoleto', obj)

            if (resp.data.status != 200) {
              this.$message.error('Erro ao gerar boleto')
              this.selectedDate = null
              return
            }

            const pdfUrl = resp.data.response
            const pdfResponse = await fetch(pdfUrl)
            const pdfBlob = await pdfResponse.blob()

            // Criar um objeto File a partir do Blob
            const arquivo = new File([pdfBlob], 'GuiaAntecipacao.pdf', { type: 'application/pdf' })

            const formData = new FormData()
            formData.append('modulo', 'renegociacaoAntecipacao')
            formData.append('nomeArquivo', 'GuiaAntecipacao.pdf')
            formData.append('sigla', this.userData.Instituto.sigla)
            formData.append('file', arquivo)
            formData.append('renegociacaoAntecipacaoId', this.parametro.id)

            await useJwt
              .postDocumento('/aws/uploadArquivoAWS', () => {}, formData)
              .then((response) => {
                if (response.data.status == 'erro') {
                  console.error(response.data.mensagem)
                  this.loading = false
                  return
                } else {
                  this.$message.success('Boleto gerado com sucesso!')
                  this.loading = false
                  window.open(response.data.s3Url)
                  this.$emit('fecharModal')
                }
              })
              .catch((error) => {
                console.error(error)
                this.$message.error('Erro ao gerar o Boleto!')
                this.loading = false
                this.$emit('atualizarGrid')
                return
              })

            this.selectedDate = null
          }
        })
      },
      async geraGuia() {
        try {
          guiaAntecipacao.vencimentoAntecipacao = this.selectedDate
          this.$refs.guiaAntecipacao.renderDoc(this.selectedDate)
        } catch (error) {
          console.error(error)
          this.$message.danger('Não foi possivel gerar a guia!')
        }
      },

      fecharModalGuiaAntecipacao() {
        this.$refs['guiaAntecipacao'].hide()
      },
    },
  }
</script>
