<template>
  <form-wizard
    shape="circle"
    color="#016b3a"
    :title="null"
    :subtitle="null"
    back-button-text="Voltar"
    next-button-text="Próximo"
    finish-button-text="Finalizar"
    class="mb-3"
    @on-complete="salvarAntecipacao"
  >
    <!-- Segurado -->
    <tab-content title="Segurado" :before-change="validationDadosSegurado">
      <validation-observer ref="tomRules" tag="form">
        <b-form-group v-show="campoSeguradoSelecionado" label="Segurado" label-for="segurado">
          <validation-provider #default="{ errors }" name="Segurado" rules="required">
            <vue-autosuggest
              id="segurado"
              v-model="antecipacao.tomador.nomeCompleto"
              :suggestions="suggestions"
              :input-props="{ id: 'autosuggest__input', class: 'form-control', placeholder: 'Digite aqui o nome do tomador' }"
              :get-suggestion-value="getSuggestionValue"
              @selected="getSuggestionValue"
              @input="fetchResults"
            >
              <template slot-scope="{ suggestion }">
                <div class="d-flex align-items-center">
                  <b-avatar :src="suggestion.item.segurado.tomador.foto ? suggestion.item.segurado.tomador.foto.foto : null" />
                  <div class="detail ml-50">
                    <b-card-text class="mb-0">
                      {{ suggestion.item.segurado.tomador.nomeCompleto }}
                    </b-card-text>
                    <small class="text-muted">
                      CPF: {{ suggestion.item.segurado.tomador.cpf }} - Parcelas: {{ suggestion.item.numeroParcelas }} -
                      <b-badge pill variant="primary"> Contrato: {{ suggestion.item.codigo }} </b-badge>
                      <b-badge v-if="suggestion.item.segurado.tomador.falecido == true" class="ml-1" pill variant="danger">
                        {{ 'Falecido' }}
                      </b-badge>
                    </small>
                  </div>
                </div>
              </template>
            </vue-autosuggest>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Campos do tomador -->
        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="CPF" label-for="cpf">
              <b-form-input id="cpf" v-model="antecipacao.tomador.cpf" readonly type="text" placeholder="CPF" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Completo" label-for="nomeCompleto">
              <b-form-input
                id="nomeCompleto"
                v-model="antecipacao.tomador.nomeCompleto"
                readonly
                type="text"
                placeholder="Nome Completo"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Categoria" label-for="categoria">
              <b-form-input
                id="categoria"
                :value="formatarInativoAposentado(antecipacao.segurado.situacaoPrevidenciaria)"
                readonly
                type="text"
                placeholder="Categoria"
              />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Social" label-for="nomeSocial">
              <b-form-input
                id="nomeSocial"
                v-model="antecipacao.tomador.nomeSocial"
                readonly
                type="text"
                placeholder="Nome Social"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Data de Nascimento" label-for="dataNascimento">
              <b-form-input
                id="dataNascimento"
                v-model="antecipacao.tomador.dataNascimento"
                readonly
                type="text"
                placeholder="Data de Nascimento"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Remuneração" label-for="remuneracaoProventos">
              <b-form-input
                id="remuneracaoProventos"
                v-model="antecipacao.segurado.remuneracao"
                readonly
                type="text"
                placeholder="Remuneração"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Contrato" label-for="contrato">
              <b-form-input
                id="contrato"
                v-model="antecipacao.contratoSelecionado.codigo"
                readonly
                type="text"
                placeholder="Contrato"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Parcelas" label-for="parcelas">
              <b-form-input
                id="parcelas"
                v-model="antecipacao.contratoSelecionado.numeroParcelas"
                readonly
                type="text"
                placeholder="Parcelas"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Certidão de Óbito  -->
    <tab-content v-if="antecipacao.tomador.falecido == true" title="Certidão de Óbito" :before-change="validationCertidaoObito">
      <validation-observer ref="certidaoObitoRules" tag="form">
        <b-form>
          <b-row>
            <b-col md="8">
              <b-form-group label="Certidão de Óbito" label-for="certidaoObito">
                <validation-provider #default="{ errors }" name="Certidão de Óbito" rules="required">
                  <b-form-file
                    id="certidaoObito"
                    :state="null"
                    ref="file"
                    accept=".pdf"
                    placeholder="Escolha uma Certidão de Óbito ou arraste até aqui ..."
                    drop-placeholder="Solte o arquivo aqui..."
                    browse-text="Escolher"
                    v-model="antecipacao.certidaoObito"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Antecipação  -->
    <tab-content title="Dados da Antecipação" :before-change="validationAntecipacao">
      <validation-observer ref="dadosAntecipacaoRules" tag="form">
        <b-form>
          <b-row>
            <!-- Quantidade de parcelas pendentes no contrato -->
            <b-col md="6">
              <b-form-group label="Total de Parcelas" label-for="totalParcelas">
                <b-form-input
                  id="totalParcelas"
                  v-model="antecipacao.contratoSelecionado.numeroParcelas"
                  readonly
                  type="text"
                  placeholder="Total de Parcelas"
                />
              </b-form-group>
            </b-col>

            <!-- Valor das parcelas no momento -->
            <b-col md="6">
              <b-form-group label="Valor da Parcela" label-for="valorParcela">
                <b-form-input
                  id="valorParcela"
                  v-model="antecipacao.contratoSelecionado.valorParcela"
                  readonly
                  type="text"
                  placeholder="Valor da Parcela"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- Valor do contrato -->
            <b-col md="6">
              <b-form-group label="Valor do Contrato" label-for="valorDoContrato">
                <b-form-input
                  id="valorDoContrato"
                  v-model="antecipacao.contratoSelecionado.valorContrato"
                  readonly
                  type="text"
                  placeholder="Valor do Contrato"
                />
              </b-form-group>
            </b-col>

            <!-- Quantidade de parcelas à antecipar -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Parcelas à Antecipar"
                v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^\d]/g, permiteAcentos: false }"
                :rules="{
                  required: true,
                  valor: Number(parcelasMaximasParaAntecipar),
                  zero: [],
                }"
              >
                <b-form-group label="Parcelas à Antecipar" label-for="parcelasAAntecipar">
                  <b-form-input
                    id="parcelasAAntecipar"
                    v-model="antecipacao.quantidadeDeParcelas"
                    type="text"
                    placeholder="Parcelas à Antecipar"
                  />
                  <small class="text-primary">Máximo: {{ parcelasMaximasParaAntecipar }}</small>
                  <br />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Resumo  -->
    <tab-content title="Resumo" :before-change="validationResumo">
      <validation-observer ref="resumoRules" tag="form">
        <b-form>
          <b-row>
            <b-table
              striped
              responsive
              foot-clone
              class="position-relative"
              :per-page="perPageParcelas"
              :current-page="currentPageParcelas"
              :items="itemsParcelas"
              :fields="fieldsParcelas"
              :sort-by.sync="sortByParcelas"
              :sort-desc.sync="sortDescParcelas"
              :sort-direction="sortDirectionParcelas"
              :busy="isBusy"
            >
              <template #cell(valorAntecipacao)="data">
                <!-- Verifica se é a última linha -->
                <span v-if="isLastRow(data) == true"
                  >{{ formatarValor(data.item.valorAntecipacao) }}
                  <b-badge pill>Pro rata: {{ valorProRata }}</b-badge>
                </span>
                <span v-else>{{ formatarValor(data.item.valorAntecipacao) }} </span>
              </template>
              <template #foot(numeroParcela)>
                <span>Total:</span>
              </template>

              <template #foot(valorAntecipacao)>
                <span>{{ valorTotalParcelas ? valorTotalParcelas : formatarValor(0) }}</span>
              </template>

              <template #foot(valorParcela)>
                {{ '' }}
              </template>

              <template #foot(vencimentoParcela)>
                {{ '' }}
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
              <b-pagination
                v-model="currentPageParcelas"
                :total-rows="totalRowsParcelas"
                :per-page="perPageParcelas"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>
          </b-row>
        </b-form>
      </validation-observer>
    </tab-content>
  </form-wizard>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { isNumber, detectarNavegador } from '@/libs/utils'
  import {
    formatarData,
    formatarInativoAposentado,
    formatarParaNumber,
    formatarValor,
  } from '@/utils/Functions/Formatacao/Formatacao'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { required } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import { VueAutosuggest } from 'vue-autosuggest'
  import Cleave from 'vue-cleave-component'
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VueAutosuggest,
      Cleave,
      FormWizard,
      TabContent,
      formatarData,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {
        origem: {
          type: String,
        },
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        cadastroFinalizado: false,
        isBusy: false,
        valorProRata: 0,
        loading: false,
        isNumber,
        formatarValor,
        formatarData,
        formatarInativoAposentado,
        number: {},
        segurado: { tomador: {} },
        antecipacao: {
          segurado: {
            remuneracao: null,
          },
          tomador: {
            cpf: null,
            nomeCompleto: null,
          },
          contratoSelecionado: {},
          seguradoSelecionado: {},
          quantidadeDeParcelas: null,
        },
        suggestions: [],

        valorTotalParcelas: 0,
        timeout: null,
        debounceMilliseconds: 300,
        required,
        valor: 0,
        sortByParcelas: '',
        sortDescParcelas: false,
        sortDirectionParcelas: 'asc',
        perPageParcelas: 12,
        currentPageParcelas: 1,
        fieldsParcelas: [
          { key: 'numeroParcela', label: 'Número Parcela' },
          {
            key: 'valorAntecipacao',
            label: 'Valor Antecipação',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valorParcela',
            label: 'Valor Anterior',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'vencimentoParcela',
            label: 'Vencimento',
            formatter: (value) => (value ? formatarData(value) : formatarData(0)),
          },
        ],
        totalRowsParcelas: 0,
        itemsParcelas: [],
        exibeDadosSegurado: false,
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        statusTipoCalculo: {
          ambas: [
            { text: 'PRICE', value: 'Price' },
            { text: 'SAC', value: 'SAC' },
          ],
          price: [{ text: 'PRICE', value: 'Price' }],
          sac: [{ text: 'SAC', value: 'SAC' }],
        },
        optionsTipoCalculo: [
          { text: 'PRICE', value: 'Price' },
          { text: 'SAC', value: 'SAC' },
        ],
        optionsFormaCalculo: [
          { text: 'Valor do Empréstimo', value: 'valorDoEmprestimo' },
          { text: 'Valor da Parcela', value: 'valorDaParcela' },
        ],
        dados: [],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          statusOpcoes: '',
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Nº Contrato' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'GerouProposta', text: 'Gerou Proposta' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Expirado', text: 'Expirado' },
        ],
        campoSeguradoSelecionado: true,
        parcelasMaximasParaAntecipar: 0,
      }
    },
    mounted() {
      this.antecipacao = {
        ...this.antecipacao,
        institutoSelecionado: this.userData.institutoSelecionado,
        segurado: {
          ...this.antecipacao.segurado,
          situacaoPrevidenciaria: formatarInativoAposentado(this.antecipacao.segurado.situacaoPrevidenciaria),
        },
      }

      this.exibeDadosSegurado = false
    },
    methods: {
      isLastRow(data) {
        const parcelaTabela = data.item.numeroParcela
        const parcelaItems = this.itemsParcelas

        if (parcelaTabela == parcelaItems[parcelaItems.length - 1].numeroParcela) {
          return true
        }
      },
      fetchResults() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.antecipacao.tomador.nomeCompleto.length >= 3) {
            useJwt
              .post('renegociacao/GetContratosParaAntecipacao', {
                descricao: this.antecipacao.tomador.nomeCompleto,
                institutoId: this.antecipacao.institutoSelecionado,
              })
              .then((response) => {
                this.suggestions = []
                if (response.data.length > 0) {
                  this.suggestions = [{ data: response.data }]
                } else {
                  this.$message.error('Nenhum registro encontrado com o nome informado.')
                }
              })
              .catch((error) => {
                console.error(error)
              })
          } else this.suggestions = []
        }, this.debounceMilliseconds)
      },
      getSuggestionValue(suggestion) {
        const { item } = suggestion

        this.campoSeguradoSelecionado = false
        this.exibeDadosSegurado = true
        this.antecipacao.id = item.id
        this.antecipacao.tomador = item.segurado.tomador
        this.antecipacao.segurado = item.segurado
        this.antecipacao.segurado.remuneracao = formatarValor(item.segurado.remuneracao)
        this.antecipacao.tomador.dataNascimento = formatarData(item.segurado.tomador.dataNascimento)
        this.antecipacao.seguradoSelecionado = item.segurado
        this.antecipacao.contratoSelecionado = item
        this.antecipacao.contratoSelecionado.valorContrato = formatarValor(item.valorContrato)
        this.antecipacao.contratoSelecionado.valorParcela = formatarValor(item.valorParcela)

        let parametro = {
          institutoId: this.userData.institutoSelecionado,
          contratoId: this.antecipacao.contratoSelecionado.id,
          quantidadeDeParcelas: this.antecipacao.contratoSelecionado.numeroParcelas,
        }

        // let parcelasParaAntecipar = this.antecipacao.contratoSelecionado.parcela

        // parcelasParaAntecipar = parcelasParaAntecipar.filter((item) => item.pagamento == null)
        // parcelasParaAntecipar = parcelasParaAntecipar.filter((item) => item.renegociacaoAntecipacaoId == null)

        // this.parcelasMaximasParaAntecipar = parcelasParaAntecipar.length

        useJwt.post('renegociacao/GetCalcularParcelasAAntecipar', parametro).then((response) => {
          this.parcelasMaximasParaAntecipar = response.data.parcelasRetorno.length
        })

        return item.nomeCompleto
      },
      validationDadosSegurado() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.tomRules.validate().then(async (success) => {
            if (success && this.antecipacao?.tomador?.nomeCompleto) {
              resolve(true)
            } else {
              let title
              if (!success) {
                title = 'Alerta! Há campos não preenchidos no formulário.'
              } else {
                title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              }
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      validationCertidaoObito() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.certidaoObitoRules.validate().then(async (success) => {
            if (success) {
              this.antecipacao.quantidadeDeParcelas = this.antecipacao.contratoSelecionado.numeroParcelas
              resolve(true)
            } else {
              let title
              if (!success) {
                title = 'Alerta! Há campos não preenchidos no formulário.'
              }
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      validationAntecipacao() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.dadosAntecipacaoRules.validate().then(async (success) => {
            if (success) {
              this.buscaParcelasAAntecipar()
              resolve(true)
            } else {
              let title
              if (!success) {
                title = 'Alerta! Verifique e preencha o formulário corretamente.'
              } else {
                title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              }
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      validationResumo() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.resumoRules.validate().then(async (success) => {
            if (success) {
              resolve(true)
            } else {
              let title
              if (!success) {
                title = 'Alerta! Há campos não preenchidos no formulário.'
              } else {
                title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              }
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      async buscaParcelasAAntecipar() {
        this.isBusy = true
        let parametro = {
          institutoId: this.userData.institutoSelecionado,
          contratoId: this.antecipacao.contratoSelecionado.id,
          quantidadeDeParcelas: this.antecipacao.quantidadeDeParcelas,
        }

        useJwt.post('renegociacao/GetCalcularParcelasAAntecipar', parametro).then((response) => {
          if (response.data?.status == 'erro') {
            this.isBusy = false
            console.error(response.data.mensagem)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Alerta!',
                  text: 'Contrato já possui uma antecipação!',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
            this.$emit('closeCadastroModal')
            return
          }

          const proRata = response?.data?.proRata
          console.debug('Parâmetros Pro Rata', {
            'Parcela do mês Vigente': proRata?.parcela,
            'Parcela do Vencimento': proRata?.parcela?.vencimentoUltimaParcela,
            Juros: proRata?.juros,
            Amortização: proRata?.amortizacao,
            'Quantidade de dias': proRata?.quantidadeDeDias,
            'Valor Pro Rata': proRata?.valorProRata,
            'Valor da Parcela': proRata?.valorComProRata,
          })

          this.valorProRata = Number(proRata?.valorProRata).toFixed(2)

          this.itemsParcelas = response.data.parcelasRetorno
          this.totalRowsParcelas = response.data.parcelasRetorno.length
          this.valorTotalParcelas = formatarValor(
            response.data.parcelasRetorno.reduce((acc, item) => acc + item.valorAntecipacao, 0),
          )
          this.isBusy = false
        })
      },
      async salvarAntecipacao() {
        const navegadorUtilizado = detectarNavegador()
        const dataToSave = {
          valor: formatarParaNumber(this.valorTotalParcelas),
          valorContrato: formatarParaNumber(this.antecipacao.contratoSelecionado.valorContrato),
          seguradoId: this.antecipacao.seguradoSelecionado.id,
          contratoId: this.antecipacao.contratoSelecionado.id,
          parcelas: this.itemsParcelas,

          institutoId: this.userData.institutoSelecionado,
          navegadorUtilizado,
        }

        this.isBusy = true
        useJwt
          .post('renegociacao/CadastraAntecipacao', dataToSave)
          .then((response) => {
            if (response.data.status === 'erro') {
              console.error(response.data.mensagem)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Houve um erro ao salvar a antecipação!',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )

              this.isBusy = false
              this.$emit('atualizarGrid')
              this.$emit('closeCadastroModal')
              return
            }

            this.$swal({
              title: 'Sucesso!',
              text: 'Antecipação criada com sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            })
            this.$message.success('Antecipação criada com sucesso!')
            this.$emit('atualizarGrid')
            this.$emit('closeCadastroModal')
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
            this.$message.error('Erro ao salvar a antecipação!')
          })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
